import dynamic from 'next/dynamic';
import styled from 'styled-components';

import { fontFamilies, spacing } from '@hultafors/snickers/helpers';

const PlugsGrid = dynamic(() =>
  import('../PlugsGrid/PlugsGrid').then((module) => module.PlugsGrid),
);

export const Title = styled.p`
  max-width: 1440px;
  width: calc(100vw - 40px);
  margin-inline: auto;
  margin-block: 0;
  font-family: ${fontFamilies.mikro};
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  &:empty {
    display: none;
  }
`;

export const StyledPlugsGrid = styled(PlugsGrid)`
  ${Title} +  & {
    margin-block-start: ${spacing.small};
  }
`;
